import { type ClassValue, clsx } from "clsx";
import { jwtDecode } from "jwt-decode";
import { twMerge } from "tailwind-merge";
import { KeyboardEvent } from "react";
import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { BookingType } from "../types/bookingTypes";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getBookingStatusStyle(status: string) {
  switch (status) {
    case "CANCELLED":
      return "bg-gray-100 text-gray-800";
    case "COMPLETED":
      return "bg-green-100 text-green-800";
    case "CONFIRMED":
      return "bg-green-100 text-green-800";
    case "PENDING":
      return "bg-yellow-100 text-yellow-800";
    case "VOIDED":
      return "bg-gray-100 text-gray-800";
    default:
      return "bg-gray-500";
  }
}
// export type booking_transaction_status = 'CANCELLED' | 'CONFIRMED' | 'PAID' | 'PENDING' | 'SETTLED' | 'VOIDED';

export const getTxnStatusStyle = (status: string) => {
  switch (status) {
    case "CANCELLED":
      return "bg-gray-100 text-gray-800";
    case "CONFIRMED":
      return "bg-orange-100 text-orange-500";
    case "PAID":
      return "bg-green-100 text-green-800";
    case "PENDING":
      return "bg-yellow-100 text-yellow-800";
    case "SETTLED":
      return "bg-green-100 text-green-800";
    case "VOIDED":
      return "bg-gray-100 text-gray-800";
    default:
      return "bg-gray-500";
  }
};

export function formatActivityTime(startTime: string, endTime: string): string {
  // Directly use startTime and endTime to construct Date objects since they're in a full datetime format
  const startDateTime = new Date(UtcToLocal(startTime));
  const endDateTime = new Date(UtcToLocal(endTime));

  // Options for formatting the time in a 12-hour format with AM/PM
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  // Format the start and end times
  const formattedStartTime = startDateTime.toLocaleTimeString("en-US", options);
  const formattedEndTime = endDateTime.toLocaleTimeString("en-US", options);

  // Combine formatted start and end times
  return `${formattedStartTime} - ${formattedEndTime}`;
}

export function formatDateFromStartTime(startTime: string): string {
  const start = new Date(UtcToLocal(startTime));

  // Adjusted options for formatting the date to "Thu, Mar 14, 2024"
  const dateOptions: Intl.DateTimeFormatOptions = {
    // weekday: "long",
    year: "numeric", // 2024
    month: "short", // Mar
    day: "2-digit", // 14
  };

  // Format the date
  const formattedDate = start.toLocaleDateString("en-US", dateOptions);

  const finalFormattedDate = formattedDate.replace(",", ", ");

  return finalFormattedDate;
}

export function calculateTimeDifference(
  startTime: string,
  endTime: string
): string {
  const start: Date = new Date(startTime);
  const end: Date = new Date(endTime);

  const difference: number = end.getTime() - start.getTime();

  const seconds: number = Math.floor((difference / 1000) % 60);
  const minutes: number = Math.floor((difference / 1000 / 60) % 60);
  const hours: number = Math.floor((difference / (1000 * 60 * 60)) % 24);

  let result: string = "";
  if (hours > 0) result += `${hours} hours`;
  if (minutes > 0) result += `${minutes} minutes`;
  if (seconds > 0) result += `${seconds} second`;

  return result.trim();
}

export function getCurrencySymbol(value: string) {
  switch (value) {
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return "$";
  }
}
export function extractTime(datetimeString: string) {
  const date = UtcToLocal(datetimeString, true) as Date;

  // Get hours and minutes
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Format and return the time in "HH:MM" format
  return `${hours}:${minutes}`;
}

export function isJwtExpired(token: string) {
  try {
    const decoded = jwtDecode(token);

    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = decoded.exp;


    return currentTime > expirationTime!;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return false;
  }
}

export function numberValidation(event: KeyboardEvent<HTMLInputElement>) {
  {
    const isValidInput = /^\d+$/.test(event.key);

    if (
      !isValidInput &&
      event.key !== "Backspace" &&
      event.key !== "Control" &&
      event.key !== "a" &&
      event.key !== "A"
    ) {
      event.preventDefault();
    }
  }
}

export function formatDate(
  dateObj: {
    _seconds: number;
    _nanos: number;
  },
  showAmPm: boolean = false
): string {
  const date = new Date(dateObj._seconds * 1000 + dateObj._nanos / 1000000);

  const dateString = date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: showAmPm,
  });

  return `${dateString} ${timeString}`;
}

export function getSessionStartsInTime(dateObj: {
  _seconds: number;
  _nanos: number;
}): string {
  const now = new Date();

  const targetDate = new Date(
    dateObj._seconds * 1000 + dateObj._nanos / 1000000
  );

  const diff = targetDate.getTime() - now.getTime();

  const absDiff = Math.abs(diff);

  const days = Math.floor(absDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((absDiff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((absDiff / (1000 * 60)) % 60);

  if (diff > 0) {
    return `Session Starts in: ${days} days, ${hours} hours, ${minutes} minutes`;
  } else {
    return `Session Ended before: ${days} days, ${hours} hours, ${minutes} minutes`;
  }
}

export const parseSessionDate = (dateTimeStr: string) => {
  return new Date(dateTimeStr.replace(" ", "T"));
};

export const formatDateToStandard = (date: Date) => {
  return format(date, "yyyy-MM-dd HH:mm:ss.SSS");
};

export const localToUtc = (
  dateInput: string | Date,
  formatOption?: boolean
) => {
  const date = typeof dateInput === "string" ? new Date(dateInput) : dateInput;
  const convertedDate = toZonedTime(date, "utc");

  if (formatOption === true) {
    return convertedDate;
  } else {
    return formatDateToStandard(convertedDate);
  }
};

export const UtcToLocal = (
  dateInput: string | Date,
  formatOption?: boolean
) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateObj =
    typeof dateInput === "string" ? new Date(dateInput + "Z") : dateInput;
  const convertedDate = toZonedTime(dateObj, timeZone);

  if (formatOption === true) {
    return convertedDate;
  } else {
    return formatDateToStandard(convertedDate);
  }
};

export const getSubDomainName = () => {
  const isTestRunning = process.env.VITE_IS_E2E === "true";

  if (isTestRunning) {
    return "spacestation";
  }

  const hostname = window.location.hostname;
  const names = hostname.split(".");

  // Check if we're on localhost or an IP address
  if (hostname === "localhost" || /^(\d{1,3}\.){3}\d{1,3}$/.test(hostname)) {
    return null;
  }

  if (names.includes("localhost")) {
    if (names.length > 1) {
      return names[0];
    }
  }

  // If we have more than 2 parts (subdomain.domain.tld), it's a subdomain
  if (names.length > 2) {
    return names[0];
  }

  return null;
};

export const formatDateForInput = (
  dateObj:
    | {
        _year: number;
        _month: number;
        _day: number;
      }
    | undefined
) => {
  if (!dateObj || !dateObj._year || !dateObj._month || !dateObj._day) {
    return "";
  }
  const year = dateObj._year.toString().padStart(4, "0");
  const month = dateObj._month.toString().padStart(2, "0");
  const day = dateObj._day.toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const generateDetailsCsv = (
  bookingData: BookingType[] | undefined
): string[][] => {
  const titlesArr = [
    "Booking Id",
    "Booking Status",
    "Created At",
    "Activity Name",
    "Session Name",
    "Booking User First Name",
    "Booking User Last Name",
    "Attendee First Name",
    "Attendee Last Name",
    "Booking Amount Paid",
    "Booking Balance Due",
    "Booking Payment Method",
    "Line Item Total",
  ];

  if (!bookingData?.length) {
    return [titlesArr];
  }

  const currencySymbol = getCurrencySymbol(
    bookingData[0]?.booking?.paidAmount?.currency
  );

  const bookingDataArr = bookingData.map((booking) => [
    booking?.uuid?.value,
    booking?.booking?.status,
    new Date(
      booking.booking.createdAt._seconds * 1000 +
        booking.booking.createdAt._nanos / 1e6
    ).toLocaleString(),
    booking?.activity?.name,
    booking?.session?.name,
    `${booking?.bookingUser?.firstName}`,
    `${booking?.bookingUser?.lastName}`,
    `${booking?.attendee?.firstName}`,
    `${booking?.attendee?.lastName}`,
    `${currencySymbol}${booking?.booking?.paidAmount?.amount}`,
    `${currencySymbol}${booking?.booking?.balanceDue?.amount}`,
    `${booking?.bookingTransaction?.paymentMethod}`,
    `${currencySymbol}${booking?.booking?.orignalAmount?.amount}`,
  ]);

  return [titlesArr, ...bookingDataArr];
};

export const generateReportCsv = (
  bookingData: BookingType[] | undefined
): string[][] => {
  const titlesArr = [
    "Activity Id",
    "Session Id",
    "Activity Name",
    "Session Name",
    "Tickets Sold",
    "Ticket Revenue",
    "Addon Revenue",
    "Total Revenue",
    "Realised Revenue",
  ];

  if (!bookingData?.length) {
    return [titlesArr];
  }

  const groupedBySession = new Map();
  if (bookingData && bookingData.length > 0) {
    bookingData.forEach((booking) => {
      const sessionId = booking.session.uuid;
      if (!groupedBySession.has(sessionId)) {
        groupedBySession.set(sessionId, []);
      }
      groupedBySession.get(sessionId).push(booking);
    });
  }

  const currencySymbol = getCurrencySymbol(
    bookingData[0]?.booking?.paidAmount?.currency
  );

  const bookingDataArr = [...groupedBySession.keys()].map((sessionId) => {
    const sessionBookings = groupedBySession.get(sessionId);
    const totalPaid = sessionBookings.reduce(
      (total: number, booking: BookingType) =>
        total + booking.booking.paidAmount.amount,
      0
    );
    const totalDue = sessionBookings.reduce(
      (total: number, booking: BookingType) =>
        total + booking.booking.balanceDue.amount,
      0
    );

    const addonAmount = sessionBookings.reduce(
      (total: number, booking: BookingType) => {
        const addonTotalForBooking = booking.addons.reduce(
          (total, addon) => total + addon.priceAmount,
          0
        );
        return total + addonTotalForBooking;
      },
      0
    );

    return [
      sessionBookings[0]?.activity?.uuid, // Activity Id
      sessionBookings[0]?.session?.uuid, // Session Id
      sessionBookings[0]?.activity.name, // Activity Name
      sessionBookings[0]?.session?.name, // Session Name
      sessionBookings.length, // Tickets Sold
      `${currencySymbol}${totalPaid + totalDue}`, // Ticket Revenue
      `${currencySymbol}${addonAmount}`, // Addon Revenue
      `${currencySymbol}${totalPaid + totalDue + addonAmount}`, // Total Revenue
      `${currencySymbol}${totalPaid + addonAmount}`, // Realised Revenue
    ];
  });

  return [titlesArr, ...bookingDataArr];
};
