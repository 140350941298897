export const BOOKING_STATUS = {
  PENDING: "Pending",
  PROCESSING: "Processing",
  COMPLETED: "Completed",
  ABANDONED: "Abandoned",
  CANCELLED: "Cancelled",
};

// This is dummy data for taxes , please replace with actual data in future
export const TOTAL_TAXES = 1;

export const ADMIN_ROLES = ["staff"];

export enum CheckoutSteps {
  LoginRequired = 1,
  Cart = 2,
  Information = 3,
  Payment = 4,
  Confirmation = 5,
}

export enum CreateBookingSteps {
  SelectUser = 1,
  SelectSession = 2,
  SelectPaymentMethod = 3,
  Confirmation = 4,
}

export enum CreatePackagedSessionSteps {
  SelectActivity = 1,
  SelectSessions = 2,
  PriceAndQuantity = 3,
  Confirmation = 4,
}

export enum SubmitRegisterSteps {
  Summary = 1,
  Comments = 2,
  Confirmation = 3,
}

export enum CreateVenueSteps {
  Details = 1,
  Summary = 2,
  Confirmation = 3,
}

export enum CreateActivitySteps {
  SelectWorkflow = 1,
  ActivityDetails = 2,
  Bookings = 3,
  Notification = 4,
}

export enum CreateOrganizationSteps {
  AccountDetails = 1,
  OrganizationDetails = 2,
  Confirmation = 3,
}

export const CACHE_KEYS = {
  USER: "user",
  ATTENDEE: "attendee",
  BOOKINGS: "bookings",
  PUBLIC_BOOKINGS: "public_bookings",

  ACTIVITIES: "activities",
  PUBLIC_ACTIVITIES: "public_activities",
  ADMIN_ACTIVITIES: "admin_activities",
  ADMIN_ACTIVITY_DETAILS: "admin_activity_details",
  ADMIN_ACTIVITY_SESSIONS: "admin_activity_sessions",
  ADMIN_ACTIVITY_SESSIONS_BY_ID: "admin_activity_sessions_by_id",
  ACTIVITY: "activity",
  SESSIONS: "sessions",
  SESSION: "session",
  PUBLIC_SESSIONS: "public_sessions",
  VENUE: "venue",
  STAFF: "staff",
  SESSION_BOOKINGS: "session_bookings",
  STAFF_USER: "staff_user",
  STAFF_USERS: "staff_users",
  BOOKING_USER: "booking_user",
  USER_BOOKINGS: "user_bookings",
  TRANSACTIONS: "transactions",
  CURRENT_BOOKING_USER: "current-booking-user",
  ORGANIZATION: "organization",
  SESSION_REGISTER: "session_register",
  COMBINED_SESSION_REGISTER: "combined_session_register",

  USER_ATTENDEES: "user-attendees",
  USER_TRANSACTIONS: "user-transactions",

  DISCOUNT_CODES: "discount-codes",
};
// activities
// transactions

// staff-user

export const enum PaymentMethodTypes {
  CASH = "CASH",
  CARD = "CARD",
  CHILDCARE_VOUCHER = "CHILDCARE_VOUCHER",
}

export const PUBLIC_BOOKINGS = {
  ALL_ACTIVITIES: "All Activities",
  ALL_ATTENDEES: "All Attendees",
  ALL_LOCATIONS: "All Locations",
};

export const enum ACTIVITY_DISCOVERY_TYPE {
  DEFAULT = "DEFAULT",
  LOCATION_BASED = "BY_LOCATION",
}

export const COUNTRIES_NAME: Record<string, string> = {
  UNITED_KINGDOM: "United Kingdom",
};
