import { useMutation, useQueryClient } from "react-query";
// import { useToast } from "@/components/ui/use-toast";
// import { AxiosError } from "axios";
import { useUserApi } from "./user.api";
import { toast } from "../../../components/ui/use-toast";
import { AxiosError } from "axios";

// type QueryType = "public" | "admin";

const KEYS = {
  createBookingUser: ["createBookingUser"],
  createOrganization: ["createOrganization"],
  createStaff: ["createStaff"],
  attendee: ["attendee"],
};

export const useCreateAttendee = () => {
  const queryClient = useQueryClient();
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.createAttendee,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.attendee);
      toast({
        title:  "Attendee Created",
        description: `Attendee has been created successfully`,
      });
    },
    onError: (error: AxiosError | any) => {
      toast({
        title: "Error",
        description: (error as AxiosError<{ message: string }>)?.response?.data
          ?.message,
        variant: "destructive",
      });
    }
  });
};

export const useUpdateAttendee = () => {
  const api = useUserApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.public.updateAttendee,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.attendee);
      toast({
        title: "Attendee Updated",
        description: `Attendee has been updated successfully`,
      });
    },
    onError: (error: AxiosError | any) => {
      toast({
        title: "Error",
        description: (error as AxiosError<{ message: string }>)?.response?.data
          ?.message,
        variant: "destructive",
      });
    }
  });
};


export const useCreateAttendeeForUser = () => {
  const api = useUserApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.admin.createAttendeeForUser,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.attendee);
      toast({
        title: "Attendee Created",
        description: `Attendee has been created successfully`,
      });
    },
    onError: (error: AxiosError | any) => {
      toast({
        title: "Error",
        description: (error as AxiosError<{ message: string }>)?.response?.data
          ?.message,
        variant: "destructive",
      });
    }
  });
}


export const useUpdateAttendeeForUser = () => {
  const api = useUserApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.admin.updateAttendeeForUser,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.attendee);
      toast({
        title: "Attendee Updated",
        description: `Attendee has been updated successfully`,
      });
    },
    onError: (error: AxiosError | any) => {
      toast({
        title: "Error",
        description: (error as AxiosError<{ message: string }>)?.response?.data
          ?.message,
        variant: "destructive",
      });
    }
})

}

export const useCreateBookingUser = () => {
  const api = useUserApi();

  return useMutation({
    mutationKey: KEYS.createBookingUser,
    mutationFn: api.public.createBookingUser,
  });
};

export const useUpdateBookingUserProfile = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.updateBookingUserProfile,
  });
};

export const useUpdateStaffUserProfile = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.admin.updateStaffUserProfile,
  });
};

export const useUpdateBookingUserPassword = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.updateBookingUserPassword,
  });
};

export const useUpdateStaffUserPassword = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.admin.updateStaffUserPassword,
  });
};

export const useForgotPassword = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.forgotPassword,
  });
};

export const useResetPassword = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.resetPassword,
  });
};

export const useSendVerificationEmail = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.sendVerificationEmail,
  });
};

export const useVerifyEmailCode = () => {
  const api = useUserApi();

  return useMutation({
    mutationFn: api.public.verifyEmailCode,
  });
};

export const useCreateOrganization = () => {
  const api = useUserApi();

  return useMutation({
    mutationKey: KEYS.createOrganization,
    mutationFn: api.public.createOrganization,
  });
};

export const useCreateStaff = () => {
  const api = useUserApi();

  return useMutation({
    mutationKey: KEYS.createStaff,
    mutationFn: api.admin.createStaff,
  });
};

// export const useCreateAttendee = () => {
//   const api = useUserApi();
//   const { toast } = useToast();

//   return useMutation({
//     mutationFn: api.public.createAttendee,
//     onError: (error: AxiosError | any) => {
//       toast({
//         title: "Error",
//         description: (error as AxiosError<{ message: string }>)?.response?.data
//           ?.message,
//         variant: "destructive",
//       });
//     },
//   });
// };
