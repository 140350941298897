import { API_BASE_URL, apiPrivateInstance } from '@/config/api';
import { useAtom } from 'jotai';
import { organizationAtom, userDetailsAtom } from '@/atom/atom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const useAxiosPrivate = () => {
  const [organization] = useAtom(organizationAtom);
  const [, setUserDetails] = useAtom(userDetailsAtom);
  const navigate = useNavigate();
  const organizationUuid = organization?.uuid;

  useEffect(() => {
    const accessToken = localStorage.getItem("token");

    const requestIntercept = apiPrivateInstance.interceptors.request.use(
      (config) => {
        // Add auth header
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        // Handle different URL patterns
        if (config.url?.includes("image/metadata")) {
          config.baseURL = `${API_BASE_URL}/api`;
          return config;
        }

        if (config.url?.includes("social-link")) {
          config.baseURL = `${API_BASE_URL}/api/admin`;
          return config;
        }

        if (
          config.url &&
          (config.url.includes("/user/staff-user") ||
            config.url.includes("/user/booking-user"))
        ) {
          config.baseURL = `${API_BASE_URL}/api/admin/organization/${organizationUuid}`;
          return config;
        }

        if (config.url?.includes("/organization/")) {
          config.baseURL = `${API_BASE_URL}/api/admin`;
          return config;
        }

        if (config.url?.includes("/api/admin/attendee")) {
          config.baseURL = `${API_BASE_URL}/api/public/organization/${organizationUuid}`;
        }

        if (
          config.url?.includes("/user") &&
          config.method !== "post"
        ) {
          config.baseURL = `${API_BASE_URL}/api/auth/organization/${organizationUuid}`;
          return config;
        }

        // Default organization route
        if (
          organizationUuid &&
          config.url &&
          !config.url.includes("/api/auth") &&
          !config.url.includes("/api/admin/attendee")
        ) {
          config.baseURL = `${API_BASE_URL}/api/admin/organization/${organizationUuid}`;
        }

        return config;
      },
      (error) => {
        console.error("Request interceptor error:", error);
        return Promise.reject(error);
      }
    );

    const responseIntercept = apiPrivateInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          setUserDetails(null);
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiPrivateInstance.interceptors.request.eject(requestIntercept);
      apiPrivateInstance.interceptors.response.eject(responseIntercept);
    };
  }, [organizationUuid, navigate, setUserDetails]);

  return apiPrivateInstance;
};
